<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('Generate contract') }}
          </h4>
        </div>
        <!-- Form: Personal Info Form -->
        <b-row class="mt-3">

          <b-col cols="2" md="2" lg="2">
            <b-form-group :label="msg('Export contract to channels')" label-for="realEstate-name" class="orange-color">
              <validation-provider #default="{ errors }" :name="msg('Hostaway')" rules="required">
                <b-form-checkbox disabled v-model="contract.hostawayApi" name="check-button" switch>
                  {{ contract.hostawayApi ? msg('Yes') : msg('No') }}
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="2" md="2" lg="2">
            <b-form-group :label="msg('newclients')" label-for="realEstate-namfsde">
              <validation-provider #default="{ errors }" :name="msg('Client')" rules="required">
                <b-form-checkbox v-model="contract.isNewClient" name="check-busfsgfsgtton" switch>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="2" md="2" lg="2">
            <b-form-group :label="msg('Pricelabs price')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('pricelabs')" rules="required">
                <b-form-checkbox v-model="isPricelabs" name="check-button" switch>
                  {{ isPricelabs ? msg('Yes') : msg('No') }}
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="msg('Paid')" label-for="realEstate-name">
              <b-form-checkbox v-model="contract.paid" name="check-button" switch>
                {{ contract.paid ? msg('Yes') : msg('No') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="msg('Over 30 days')" label-for="realEstate-name">
              <b-form-checkbox v-model="contract.over30Days" name="check-button" switch>
                {{ contract.over30Days ? msg('Yes') : msg('No') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="contract.over30Days" class="mt-1">
          <b-col cols="12">
            <b-card class="shadow-sm">
              <b-form-group>
                <b-form-radio-group
                    v-model="contract.platform30DaysId"
                    name="extendedStayPlatforms"
                    class="d-flex flex-wrap justify-content-between"
                >
                  <b-form-radio
                      v-for="option in options"
                      :key="option.value"
                      :value="option.value"
                      button
                      button-variant="outline-primary"
                      class="text-center custom-radio"
                  >
                    <div class="d-flex flex-column align-items-center">
                      <img :src="option.image" alt="Option Image" class="option-image mb-1">
                      <span>{{ option.text }}</span>
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <template v-if="!contract.isNewClient">
            <b-col cols="12" md="12" lg="6">
              <b-form-group :label="msg('Client Id')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('Client')" rules="required">
                  <client-picker v-model="contract.clientId"/>
                  <small class="text-danger">{{ msg(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
          <template v-else>
            <b-col cols="12" md="6" lg="3">
              <b-form-group :label="msg('Firstname')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('Client')" rules="required">
                  <b-form-input v-model="contract.client.firstname"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group :label="msg('Lastname')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('Lastname')" rules="required">
                  <b-form-input v-model="contract.client.lastname"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group :label="msg('Phone')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('Phone')" rules="required">
                  <b-form-input v-model="contract.client.phone"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group :label="msg('Email')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('Email')" rules="required">
                  <b-form-input v-model="contract.client.email"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group :label="msg('Address')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('Address')" rules="required">
                  <b-form-input v-model="contract.client.address.street"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="2">
              <b-form-group :label="msg('Zip')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('Zip')" rules="required">
                  <b-form-input v-model="contract.client.address.zip"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="2">
              <b-form-group :label="msg('City')" label-for="realEstate-name">
                <validation-provider #default="{ errors }" :name="msg('City')" rules="required">
                  <b-form-input v-model="contract.client.address.city"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <!-- Field: Country -->
            <b-col cols="12" md="6" lg="3">
              <b-form-group :label="msg('Country')" label-for="client-country">
                <validation-provider #default="{ errors }" :name="msg('Country')" rules="required">

                  <v-select
                      v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="contract.client.address.country"
                      :options="allCountries"
                      class="w-100"
                      label="name"
                      :reduce="(lang) => lang.iso"
                      autocomplete="on"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="2">
              <b-form-group :label="msg('Number of guests')" label-for="realEstate-type">
                <validation-provider #default="{ errors }" :name="msg('Number of guests')" rules="required">
                  <b-form-input id="realEstate-sell-price" type="number"
                                v-model="contract.numberOfGuests"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
        </b-row>


        <b-row>


          <b-col cols="12" md="12" lg="12">
            <b-form-group :label="msg('Real estates')" label-for="realEstate-name">
              <real-estate-picker multiple v-model="contract.propertyIds"/>
            </b-form-group>
          </b-col>

          <b-col class="mt-2" cols="4" md="4" lg="4">
            <b-form-group :label="msg('Start')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Start date')" rules="required">
                <calendar-picker v-model="contract.bnbStyleRent.start"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="mt-2" cols="4" md="4" lg="4">
            <b-form-group :label="msg('End')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('End date')" rules="required">
                <calendar-picker v-model="contract.bnbStyleRent.end"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>

          <b-col cols="12" class="justify-content-center">
            <h5>{{ msg("Cancellation options") }}</h5>
            <v-select
                v-model="cancelationOptionPicked"
                :options="cancellationOptions"
                label="name"
                :clearable="false"
                :placeholder="msg('Select a cancellation option')"
                :reduce="option => option.id"

            ></v-select>
          </b-col>

          <b-col style="margin-top: 5vh" cols="3" md="3" lg="3">

            <b-button
                :disabled="!isPricelabs || contract.propertyIds.length<1 || this.contract.bnbStyleRent.start == null || this.contract.bnbStyleRent.end == null"
                variant="danger"
                style="float: right"
                @click="calculatePricelabsPrice"
                label-for="realEstate-name">
              {{ msg('Calculate Pricelabs price') }}
            </b-button>
          </b-col>

        </b-row>

        <b-row class="mt-2" v-if="contract.propertyIds!=null && contract.propertyIds.length>0">
          <!-- Iterate over the properties map -->
          <template v-if="isPricelabs">
            <b-col cols="12" md="6" lg="3" v-for="(address, id) in realEstatesIdAddressMap" :key="id">
              <b-form-group :label="`${msg('Price for')} ${address}`" :label-for="`price-${id}`">
                <validation-provider #default="{ errors }" :name="`${msg('Price for')} ${address}`" rules="required">
                  <b-form-input readonly :id="`realEstate-sell-price-${id}`" type="number"
                                v-model="contract.prices[id]"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
          <template v-else>

            <b-col cols="12" md="6" lg="3" v-for="(address, id) in realEstatesIdAddressMap" :key="id">
              <b-form-group :label="`${msg('Price for')} ${address}`" :label-for="`price-${id}`">
                <validation-provider #default="{ errors }" :name="`${msg('Price for')} ${address}`" rules="required">
                  <b-form-input :id="`realEstate-sell-price-${id}`" type="number" step="any"
                                v-model="contract.prices[id]"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </template>
        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ClientPicker from "@/components/components/picker/clientPicker";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";

export default {
  name: 'treasureContractRentPanelMulti',
  components: {RealEstatePicker, ClientPicker},
  data() {
    return {
      loaded: false,
      pricelabPrices: [],
      error: null,
      selectedOption: null,
      firstProperty: null,
      options: [
        {
          value: "Housing anywhere",
          text: "Housing anywhere",
          image: require('@/assets/images/platforms/housing.jpg'),
        },
        {
          value: "Homelike",
          text: "Homelike",
          image: require('@/assets/images/platforms/homelike.jpg'),
        },
        {
          value: "Wunderflats",
          text: "Wunderflats",
          image: require('@/assets/images/platforms/wunder.jpg'),
        },
        {
          value: "Treasure home",
          text: "Treasure home",
          image: "https://treasurehome.at/uploads/images/tLogo.png",
        }
      ],

      contractId: 0,
      isPricelabs: false,
      cancelationOptionPicked: null,
      cancellationOptions: [],

      contract: {
        prices: {},
        propertyIds: [],
        hostawayApi: true,
        isNewClient: true,
        name: '',
        client: {
          firstname: null,
          email: null,
          phone: null,
          lastname: null,
          address: {
            street: null,
            zip: null,
            city: null,
            country: null
          },
        },

        bnbStyleRent: {
          platform: null,
          active: true,
          start: null,
          end: null,
          totalPayment: 0.0,
          oneTimePayment: false,
          totalPaymentDiscount: null,
          totalServiceFee: null,
          totalPriceHandlingFee: null,
        },
        type: null,
      },
      languageOptions: [
        // Define your language options here, excluding Arabic
        {id: 'EN', name: 'English'},
        {id: 'ES', name: 'Spanish'},
        {id: 'FR', name: 'French'},
        {id: 'DE', name: 'German'},
        {id: 'IT', name: 'Italian'},
        {id: 'PT', name: 'Portuguese'},
        {id: 'RU', name: 'Russian'},
        {id: 'CN', name: 'Chinese'},
        {id: 'JP', name: 'Japanese'}
        // Add more languages as needed
      ],
      Refilter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}, featureStatus: null},
        lte: {realEstatePrices: {}},
        gte: {realEstatePrices: {}},
        in: {}
      },
      realEstatesIdAddressMap: {},
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
    'contract.propertyIds': {
      handler(newPropertyIds, oldPropertyIds) {
        // This function will be called whenever contract.propertyIds changes
        console.log('propertyIds changed', newPropertyIds, oldPropertyIds);
        this.getProperties()
      },
      deep: true, // Use deep watcher to detect changes within the array
    },
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allCountries() {
      return Object.values(this.getAllCountries)
    },
    propertiesWithZeros() {
      // Check if contract.propertyIds exists and has a length
      if (this.contract && Array.isArray(this.contract.propertyIds) && this.contract.propertyIds.length > 0) {
        // Create an array of the same length, with each element being { value: 0 }
        return this.contract.propertyIds.map(() => ({value: 0}));
      }
      return []; // Return an empty array if contract.propertyIds is not set or empty
    },
  },
  created() {

    this.onLoad()
    this.$root.$on('crm::submitContract', (this.listeners.submit = () => {
      this.submit()
    }))
    this.$root.$on('crm::checkContract', (this.listeners.checkContract = () => {
      this.checkContract()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitContract', this.listeners.submit)
    this.$root.$off('crm::checkContract', this.listeners.checkContract)
  },
  methods: {
    ...mapActions('realEstateContracts', {
      _createContractMulti: 'createContractMulti', _checkCreateMulti: 'checkCreateMulti'
    }),
    ...mapActions('realEstate', ['getAllRealEstates']),
    ...mapActions('pricelabs', ['calcPrices']),
    setData(propertyIds, start, end) {
      this.contract.propertyIds = propertyIds
      this.contract.bnbStyleRent.start = start
      this.contract.bnbStyleRent.end = end
      this.loaded = true
    },
    getProperties() {
      this.Refilter.in = {id: this.contract.propertyIds}
      this.getAllRealEstates({
        page: 1,
        amount: 9999,
        filter: this.Refilter,
        sortcolumn: 'updatedDate',
        sortby: 'desc',
      }).then(this.onRERetrieve, (error) => this.error = error)
    },
    calculatePricelabsPrice() {
      let $this = this;
      let request = {
        realEstateIds: this.contract.propertyIds,
        start: this.contract.bnbStyleRent.start,
        end: this.contract.bnbStyleRent.end,
      }
      this.calcPrices({body: request}).then((ans) => {

        $this.contract.prices = ans

      }, (error) => this.error = error)
    },

    onRERetrieve(data) {
      this.firstProperty = data["RealEstates"][0]
      this.realEstatesIdAddressMap = data["RealEstates"].reduce((acc, cur) => {
        acc[cur.id] = cur.address + " " + cur.house + "/" + cur.apt + " " + cur.zip
        return acc
      }, {});

      this.loadCancellationOptions();

    },

    onLoad() {
      this.loaded = true

    }
    ,
    loadCancellationOptions() {
      if (this.firstProperty == null) {
        return;
      }
      if (this.firstProperty.realEstateCancel == null) {
        this.cancellationOptions = [
          {id: 'nonRefundable', name: this.msg('cancellationOptions.nonRefundable')} // Static non-refundable option
        ];
        return;
      }

      // Always include the static non-refundable option with translation
      this.cancellationOptions = [
        {id: 'nonRefundable', name: this.msg('cancellationOptions.nonRefundable')}
      ];

      // Dynamic options based on realEstateCancel booleans, with translations
      if (this.firstProperty.realEstateCancel.threeDays) {
        this.cancellationOptions.push({
          id: 'threeDays',
          name: `${this.msg('cancellationOptions.cancelUpTo')} 3 ${this.msg('cancellationOptions.days')} (${this.msg('cancellationOptions.extraFee')}: ${this.firstProperty.realEstateCancel.threeDaysFeePercent}%)`
        });
      }

      if (this.firstProperty.realEstateCancel.sevenDays) {
        this.cancellationOptions.push({
          id: 'sevenDays',
          name: `${this.msg('cancellationOptions.cancelUpTo')} 7 ${this.msg('cancellationOptions.days')} (${this.msg('cancellationOptions.extraFee')}: ${this.firstProperty.realEstateCancel.sevenDaysFeePercent}%)`
        });
      }

      if (this.firstProperty.realEstateCancel.fourteenDays) {
        this.cancellationOptions.push({
          id: 'fourteenDays',
          name: `${this.msg('cancellationOptions.cancelUpTo')} 14 ${this.msg('cancellationOptions.days')} (${this.msg('cancellationOptions.extraFee')}: ${this.firstProperty.realEstateCancel.fourteenDaysFeePercent}%)`
        });
      }
    }

    ,
    createContract() {
      let $this = this
      if (this.contract.paid == null)
        this.contract.paid = false

      if (this.contract.over30Days == null)
        this.contract.over30Days = false
      else if (this.contract.over30Days && this.contract.platform30DaysId == null) {
        this.contract.platform30DaysId = "Treasure home"
      }

      this.contract.cancelationOptionPicked = this.cancelationOptionPicked;

      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._createContractMulti(this.contract)
            .then(
                (id) => {

                  $this.contractId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id
                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
            .finally(() => {
              if ($this.contractId != null && $this.contractId !== 0 && $this.contractId !== 'success')
                this.$router.push(`/real_estate/contracts/rent/${$this.contractId}`);

            })
      })
    },

    validate(field) {
      return this.$v.contract[field] != null && this.$v.contract[field].$error === true
    }
    ,
    submit() {
      return this.createContract()
    },
    checkContract() {
      let $this = this;
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._checkCreateMulti(this.contract)
            .then(
                (id) => {

                  $this.contractId = id
                  $this.$emit('checked', {
                    status: true,
                    server: id,
                    id
                  })
                },
                (a) => $this.$emit('checked', {
                  status: false,
                  server: a
                })
            )

      })
    }

  }


}
</script>

<style scoped>
.orange-label > label {
  color: orange;
}

.option-image {
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
</style>

